import * as React from "react"
import PropTypes from "prop-types"

const MapItem = ({ title, country, children, className }) => (
  <div className={` ${className}`}>
    <p className="font-bold">{title}</p>
    <div className="inline-flex items-center mt-2">
      {children}
      <span className="ml-2 text-layout-gray-300">{country}</span>
    </div>
  </div>
)

MapItem.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
}

MapItem.defaultProps = {
  
}

export default MapItem
