import * as React from "react"
import PropTypes from "prop-types"

const ConceptItem = ({ title, text, className }) => (
  <div className={` ${className}`}>
    <p className="text-2xl">{title}</p>
  </div>
)

ConceptItem.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

ConceptItem.defaultProps = {
  
}

export default ConceptItem
