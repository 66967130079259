import * as React from "react"
import { CookieBanner } from '@palmabit/react-cookie-law'
import { Location } from '@reach/router'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

const Banner = ({ }) => {

  const onAccept = () => {
    initializeAndTrack(Location)
  }
  return (
    <CookieBanner
      className="fixed max-w-xl rounded-md left-0 bottom-0 bg-white z-50 p-4 m-4 shadow"
      message="This website stores cookies on your computer. These cookies are used to improve your website experience and provide more personalized services to you, both on this website and through other media. To find out more about the cookies we use, see our Privacy Policy.
      We won't track your information when you visit our site. But in order to comply with your preferences, we'll have to use just one tiny cookie so that you're not asked to make this choice again."
      wholeDomain={true}
      onAccept = {onAccept}
      managePreferencesButtonText="Manage Cookies"
      onAcceptMarketing = {onAccept}
      showStatisticsOption={false}
      showPreferencesOption={false}
      policyLink="/privacy/"
      styles={{
        dialog: {},
        button: {},
        message: {}
      }}
    />
  )
}


Banner.propTypes = {
  
}

Banner.defaultProps = {
  
}

export default Banner
