import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'

import 'bootstrap/dist/css/bootstrap.min.css';

import Layout from "../components/layout"
import Seo from "../components/seo"
import Card from "../components/card"
import Map from "../components/map"
import Button from "../components/button"
import Newsletter from "../components/newsletter"
import ConceptSection from "../components/concept"
import IconBookLeaf from "../../static/icons/book-leaf-open.svg";
import IconLeafBulb from "../../static/icons/leaf-bulb.svg";
import IconFlame from "../../static/icons/flame.svg";
import IconWarningElectricity from "../../static/icons/warning-electricity.svg";
import IconMonitorHeart from "../../static/icons/monitor-heart.svg";
import IconLeaf from "../../static/icons/ecology-leaf.svg";
import IconHouseLeaf from "../../static/icons/house-leaf.svg";
import IconBrain from "../../static/icons/brain.svg";
import IconFileText from "../../static/icons/file-text.svg";
import Publication from "../components/publication"

const IndexPage = ({ data }) => {

  const document = data.prismicHomepage.data;

  return (
    <Layout>
      <Seo title="ECOQube" />
      <div className="grid lg:grid-cols-7 max-w-screen-xl mx-auto px-4">
        <div className="lg:col-span-4 relative z-20">
          <p className="uppercase tracking-widest">Zonal Heat Management System</p>
          <h1 className="font-display font-bold text-4xl lg:text-5xl lg:leading-tight mt-4 leading-tight">{document.title.text}</h1>
          <p className="mt-4 text-2xl mb-6 leading-relaxed">ECO-Qube is a holistic management system which aims to enhance energy efficiency and cooling performance by orchestrating both hardware and software components in edge computing applications.</p>
          <Button link="/technology">Learn more</Button>
        </div>
        <div className="absolute z-10 right-0 top-0 -mr-48 -mt-32 opacity-30 lg:opacity-100">
          <img src={'../cube.png'} />
        </div>
      </div>
      <div className="max-w-screen-xl mx-auto px-4 mt-20">
        <div className="bg-white rounded-md p-4 shadow">
          <div className="-ml-6 -mt-4 flex items-center justify-center flex-wrap lg:justify-between">
            <img src={'../logos/helio.png'} className="ml-6 mt-4 w-16" />
            <img src={'../logos/empa.png'} className="ml-6 mt-4 w-16" />
            <img src={'../logos/blockheating.png'} className="ml-6 mt-4 w-24" />
            <img src={'../logos/lulea.png'} className="ml-6 mt-4 w-16" />
            <img src={'../logos/rm-solution.png'} className="ml-6 mt-4 w-16" />
            <img src={'../logos/lande.png'} className="ml-6 mt-4 w-16" />
            <img src={'../logos/endoks.png'} className="ml-6 mt-4 w-16" />
            <img src={'../logos/greenit.png'} className="ml-6 mt-4 w-16" />
            <img src={'../logos/dstech.png'} className="ml-6 mt-4 w-16" />
            <img src={'../logos/sdia.png'} className="ml-6 mt-4 w-16" />
            <img src={'../logos/bitnet.png'} className="ml-6 mt-4 w-16" />
          </div>
        </div>
      </div>
      <div className="relative w-screen">
        <div className="absolute z-10 top-0 left-0 w-screen">
          <img src={'../background-line.jpg'} className="w-full" />
        </div>
        <p className="mt-20 lg:mt-40 text-center text-3xl md:text-4xl relative z-20 max-w-screen-md px-4 mx-auto leading-normal md:leading-normal">
          Data centres will need about 4 to 6% of the world's energy in 2022. <span className="font-bold">Our mission is to use this energy wisely and effectively.</span> 
        </p>
      </div>
      <div className="max-w-screen-xl mx-auto px-4 relative z-20 mt-20 lg:mt-40">
        <div className="flex justify-center items-center">
          <div className="relative inline-block mr-6 flex-shrink-0">
            <img src={'../cube-skelleton.svg'} className="w-16" />
            <IconBookLeaf className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -mt-1" />
          </div>
          <h2 className="text-4xl lg:text-5xl font-bold font-display md:text-center">ECO-Qube Concept and approach</h2>
        </div>
        <ConceptSection className="mt-12" />
        <div className="mt-24 lg:mt-4 md:flex md:justify-center md:items-center text-center md:text-left border-b border-layout-gray-700 pb-12">
          <p className="text-2xl md:mr-6 mb-4 md:mb-0">Learn more about the technology that will change data centres forever</p>
          <Button link="/technology">Learn more</Button>
        </div>
      </div>
      <div className="max-w-screen-xl mx-auto px-4 relative z-20 mt-20 lg:mt-40">
        <div className="flex justify-center items-center">
          <div className="relative inline-block mr-6 flex-shrink-0">
            <img src={'../cube-skelleton.svg'} className="w-16" />
            <IconLeafBulb className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -mt-1" />
          </div>
          <h2 className="text-4xl lg:text-5xl font-bold font-display md:text-center">How we improve Data Centres</h2>
        </div>
        <div className="grid md:grid-cols-2 gap-8 lg:gap-12 mt-12 items-start">
          <div className="grid gap-12 items-start">
            <Card
              className="bg-danger-base"
              title="Zonal Heat Management"
              text="With the help of zonal heat management, smart workload orchestration will be able to transfer the workload from hot areas to colder areas to keep the system thermally balanced for even better energy efficiency. Furthermore, to benefit from different outside variables (like seasonal characteristics, outside temperature and time differences), smart workload orchestration of ECO-Qube will be able to transfer the workload between different data centres from different buildings, cities, countries and continents to benefit from the best conditions for the minimum energy consumption."
            >
              <IconFlame />
            </Card>
            <Card
              className="bg-layout-gray-900"
              title="Monitoring"
              text="ECO-Qube will change the conventional sensor approach for thermal mapping, replacing the sensors with digital components’ own sensors. This will allow ECO-Qube to create a dynamic zonal heat map to be used by zonal heat management system which will also benefit from digital components power consumption to predict the thermal changes in advance to maintain the thermal balance."
            >
              <IconMonitorHeart className="w-20 h-20 fill-current" />
            </Card>
            <Card
              className="bg-layout-ocean-base"
              title="AI Based"
              text="Identification of hot spots and optimisation of air flow and cooling power via artificial intelligence by simultaneously controlling CPU loads and the energy management system."
            >
              <IconBrain className="w-20 h-20 fill-current" />
            </Card>
          </div>
          <div className="grid gap-12 items-start">
            <Card
              className="bg-primary-base"
              title="CO2 savings"
              text="ECO-Qube is the holistic approach for data centre cooling that will contribute in global endeavor to reduce energy consumption by achieving 20% energy savings and 20% CO2 emissions reduction. ECO-Qube’s smart cooling system will combine smart monitoring capabilities with advanced modelling and simulations techniquest to adapt simultaneously both, the cooling system and IT devices load for the best airflow and cooling performance with minimum energy consumption."
            >
              <IconLeaf className="fill-current w-20 h-20" />
            </Card>
            <Card
              className="bg-danger-base"
              title="Smart Workload Orchestration"
              text="ECO-Qube brings workload management on another level by benefiting from zonal heat management system which allows ECO-Qube to realize smart workload orchestration by virtually mapping the servers to create a thermal balance to reduce the cooling power consumption with the IT power consumption by conventional smart workload orchestration."
            >
              <IconHouseLeaf className="w-20 h-20 fill-current" />
            </Card>
            <Card
              className="bg-layout-highlight-base"
              title="Energy savings"
              text="ECO-Qube introduces an innovative cooling solution which focuses on energy savings for data centre components with the highest amount of electricity consumption; computing equipment (CPU, power supply, storage etc.; ~50%) and cooling systems (~40%). ECO-Qube approach includes preventive strategies to reduce consumption of the computing equipment & cooling systems and supportive strategies to achieve a high share of the ICT energy consumption covered by sustainable energy sources."
            >
              <div className="flex items-center justify-center">
                <IconWarningElectricity className="mr-6 flex-shrink-0 w-20 h-20 fill-current" />
                <span className="text-7xl">20 %</span>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <div className="max-w-screen-xl mx-auto px-4 mt-20 lg:mt-40">
        <Map />
      </div>
      <div className="max-w-screen-xl mx-auto px-4 mt-20 lg:mt-40">
        <div className="flex justify-center items-center">
          <div className="relative inline-block mr-6 flex-shrink-0">
            <img src={'../cube-skelleton.svg'} className="w-16" />
            <IconFileText className="absolute w-8 h-8 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -mt-1" />
          </div>
          <h2 className="text-4xl lg:text-5xl font-bold font-display md:text-center">Latest Publications</h2>
        </div>
        {/* Maximum: 6 Items; Sorting: Latest Publication Date */}
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 mt-12 gap-8">
        {data.allPrismicPublications.edges.map((edge) => (
            <Publication 
              key={edge.node.id}
              date={edge.node.data.date}
              title={edge.node.data.title.text}
              url={edge.node.data.file.url}
              className="mt-6 first:mt-0" 
              category={edge.node.data.category}
            />
          )
        )}
        </div>
        <div className="mt-24 lg:mt-12 md:flex md:justify-center md:items-center text-center md:text-left border-b border-layout-gray-700 pb-12">
          <p className="text-2xl md:mr-6 mb-4 md:mb-0">Take a look at more official publications</p>
          <Button link="/publications">Go to Publications</Button>
        </div>
      </div>
    <div className="max-w-screen-sm mx-auto px-4 mt-20 lg:mt-40 relative">
      <Newsletter />
    </div>
  </Layout>
  )
}

export const query = graphql`
  query IndexPage {
    prismicHomepage {
      data {
        title {
          text
        }
      }
    }
    allPrismicPublications(limit: 6) {
      edges {
        node {
          id
          data {
            date(formatString: "DD.MM.YYYY")
            title {
              text
            }
            file {
              url
            }
            category
          }
        }
      }
    }
  }
`

export default IndexPage
