import * as React from "react"
import PropTypes from "prop-types"

const Card = ({ title, text, tagline, children, className }) => (
  <div className={`rounded p-8 ${className}`}>
    <div className="mb-8">
      {children}
    </div>
    {tagline ? 
      <p className="uppercase tracking-widest font-bold opacity-80">{tagline}</p>
    : null}
    <p className="font-bold font-display text-3xl mb-4">{title}</p>
    <p>{text}</p>
  </div>
)

Card.propTypes = {
  tagline: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Card.defaultProps = {
}

export default Card
