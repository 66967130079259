import * as React from "react"
import { Link } from "gatsby"
import IconTwitter from "../../static/icons/twitter.svg";
import IconLinkedIn from "../../static/icons/linkedin.svg";
import ImageFlag from "../../static/flag-europe.jpeg";
import Banner from "./banner"


const Footer = () => (
  <>
    <footer className="mt-20 lg:mt-40 relative px-4 py-8">
        <div className="absolute bottom-0 left-0 -mb-8">
          <img src={'../background-footer.png'} className="w-full" />
        </div>
        <div className="max-w-4xl mx-auto px-4 mb-20 relative z-20">
          <div className="border-2 border-layout-gray-800 rounded p-6 text-white flex">
            <div className="w-28 flex-shrink-0">
              <img src={ImageFlag} alt="Flag of Europe" className="border-white border" />
            </div>
            <div className="pl-8">
              <p className="font-bold" style={{fontFamily: 'Arial'}}>The ECO-Qube project has received funding from the European Union’s Horizon 2020 research and innovation programme under grant agreement No 956059</p>
              <p className="text-base">The contents of this publication are the sole responsibility of ECO-Qube and do not necessarily reflect the opinion of the European Union.</p>
            </div>
          </div>
        </div>
        <div className="relative z-20 flex flex-col items-center sm:flex-row sm:justify-between sm:items-center max-w-screen-xl mx-auto px-4">
          <div className="flex justify-between items-center w-full">
            <Link to="/">
              <img src={'../logo.png'} className="w-full" />
            </Link>
            <div className="flex items-center">
              <a href="https://twitter.com/eco_qube" target="_blank"><IconTwitter className="w-6 h-6 ml-6 fill-current text-white" /></a>
              <a href="https://www.linkedin.com/company/eco-qube/" target="_blank"><IconLinkedIn className="w-6 h-6 ml-6 fill-current text-white" /></a>
            </div>
          </div>
          <div className="text-base flex items-center mt-12 sm:mt-0 sm:ml-12 -ml-6">
            <Link to="/publications/" className="text-layout-gray-400 py-1 px-2 ml-6 rounded hover:bg-layout-gray-900 transition">Publications</Link>
            <Link to="/privacy/" className="text-layout-gray-400 py-1 px-2 ml-6 rounded hover:bg-layout-gray-900 transition">Privacy</Link>
            <Link to="/imprint/" className="text-layout-gray-400 py-1 px-2 ml-6 rounded hover:bg-layout-gray-900 transition">Imprint</Link>
          </div>
        </div>
      </footer>
      <Banner />
  </>
)

export default Footer
