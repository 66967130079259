import * as React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"

import ConceptItem from "../components/concept/item"
import ConceptTitle from "../components/concept/title"

const ConceptSection = ({ children, className }) => (
  <div className={`grid grid-cols-4 gap-12 relative ${className}`}>
    <div className="col-span-4 md:col-span-2 lg:col-span-1 order-2 relative z-20">
      <ConceptTitle title="Supportive Strategies" number="1" />
      <ConceptItem
        title="Renewable Energy Application"
        className="mt-6 lg:mt-12"
      />
      <ConceptItem
        title="Waste Heat Valorisation"
        className="mt-6 lg:mt-12"
      />
      <ConceptItem
        title="Alternative Energy Strategies"
        className="mt-6 lg:mt-12"
      />
      <ConceptItem
        title="Smart Power Management"
        className="mt-6 lg:mt-12"
      />
    </div>
    <div className="col-span-4 lg:col-span-2 order-1 absolute top-0 -mr-48 md:mr-0 right-0 m-auto lg:static opacity-50 lg:opacity-100">
      <img src={'cube-layer.png'} />
    </div>
    <div className="col-span-4 md:col-span-2 lg:col-span-1 order-2 lg:order-none mt-12 md:mt-0 relative z-20">
      <ConceptTitle title="Preventive Strategies" number="2" />
      <ConceptItem
        title="Component Observation"
        className="mt-6 lg:mt-12"
      />
      <ConceptItem
        title="Zonal Heat Management"
        className="mt-6 lg:mt-12"
      />
      <ConceptItem
        title="CFD Analysis"
        className="mt-6 lg:mt-12"
      />
      <ConceptItem
        title="Smart Scheduling"
        className="mt-6 lg:mt-12"
      />
      <ConceptItem
        title="Smart Cooling"
        className="mt-6 lg:mt-12"
      />
    </div>
  </div>
)

ConceptSection.propTypes = {
}

ConceptSection.defaultProps = {
  
}

export default ConceptSection
