import * as React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import MapItem from "../components/map/item"
import IconTurkey from "../../static/icons/flag-turkey.svg";
import IconGermany from "../../static/icons/flag-germany.svg";
import IconSweden from "../../static/icons/flag-sweden.svg";
import IconSpain from "../../static/icons/flag-spain.svg";
import IconNetherlands from "../../static/icons/flag-netherlands.svg";
import IconEurope from "../../static/icons/flag-europe.svg";
import IconSwitzerland from "../../static/icons/flag-switzerland.svg";

const Map = ({ title }) => (
  <div className="rounded bg-layout-gray-900">
    <div className="relative rounded-t">
      <img src={'map-europe.png'} className="w-full rounded-t md:opacity-50 lg:opacity-100" />
      <div className="md:absolute md:top-0 md:right-0 md:left-0 md:bottom-0 lg:left-auto lg:bottom-auto p-4 md:p-12 lg:w-1/2">
        <h2 className="font-display font-bold text-4xl">Involved on ECO-Qube</h2>
        <div className="mt-6">
          <p className="text-base">01. Oktober 2020</p>
          <div className="rounded-full bg-primary-300 relative h-3 my-2">
            <span className="bg-primary-base rounded-full w-3/6 h-full absolute top-0 left-0"></span>
          </div>
          <p className="text-right text-base lg:text-layout-gray-300">30. September 2023</p>
        </div>
      </div>
      <div className="bg-black bg-opacity-60 inline-flex rounded bottom-0 m-6 right-0 p-4 text-sm">
        <IconEurope className="flex-shrink-0" />
        <p className="ml-4"><span className="font-bold">The project has received funding from the European Union's Horizon 2020</span> research and innovation programme under grant agreement No 956059</p>
      </div>
    </div>
    <div className="p-6 md:p-8 text-base">
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        <MapItem title="TASARIM SIMULASYON TEKNOLOJILERI SANAYI VE TICARET AS (D&S TECH)" country="Turkey"><IconTurkey /></MapItem>
        <MapItem title="ENDOKS ENEJI DAGITIM SISTEMLERI SANAYI ITHALAT VE IHRACAT LIMITED SIRKETI (ENDOKS Energy)" country="Turkey"><IconTurkey /></MapItem>
        <MapItem title="EIDGENOSSISCHE MATERIALPRUFUNGS- UND FORSCHUNGSANSTALT (EMPA)" country="Switzerland"><IconSwitzerland /></MapItem>
        <MapItem title="R2M SOLUTION SPAIN SL (R2M)" country="Spain"><IconSpain /></MapItem>
        <MapItem title="SUSTAINABLE DIGITAL INFRASTRUCTURE ALLIANCE EV (SDIA)" country="Germany"><IconGermany /></MapItem>
        <MapItem title="HELIO AG" country="Switzerland"><IconSwitzerland /></MapItem>
        <MapItem title="STICHTING GREEN IT CONSORTIUM REGIO AMSTERDAM (GITA)" country="Netherlands"><IconNetherlands /></MapItem>
        <MapItem title="BLOCKHEATING BV" country="Netherlands"><IconNetherlands /></MapItem>
        <MapItem title="LULEA TEKNISKA UNIVERSITET (LTU)" country="Sweden"><IconSweden /></MapItem>
        <MapItem title="LANDE ENDUSTRIYEL METAL URUNLER SANAYI VE TICARET AS (LANDE)" country="Turkey"><IconTurkey /></MapItem>
        <MapItem title="BITNET BILISIM HIZMETLERI LIMITED SIRKETI (BitNet)" country="Turkey"><IconTurkey /></MapItem>
      </div>
    </div>
  </div>
)

Map.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
}

Map.defaultProps = {
  title: 'Map Title',
  text: 'In doing so, ECO-Qube monitors the CPU load, power consumption and temperatures of the IT devices in order to react to the dynamic cooling requirements of the system with zonal thermal management.',
}

export default Map
