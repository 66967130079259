exports.linkResolver = (doc) => {
  if (doc.isBroken) {
    return '/404'
  }

  if (doc && doc.type === 'publications') {
    return `/resources/${doc.uid}`
  }

  if (doc && doc.type === 'news') {
    return `/news/${doc.uid}`
  }

  if (doc && doc.type === 'events') {
    return `/events/${doc.uid}`
  }

  if (doc && doc.type === 'page') {
    return `/${doc.uid}`
  }
  
  return '/'
}
