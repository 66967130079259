import * as React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import IconEnvelope from "../../static/icons/envelope-open.svg"
import HubspotForm from "react-hubspot-form"

const Newsletter = () => (
  <>
    <div className="absolute top-0 left-0 transform -ml-20 mt-20 -translate-x-full">
      <img src={'../stairs-left.png'} />
    </div>
    <div className="absolute top-0 right-0 transform -mr-20 translate-x-full">
      <img src={'../stairs-right.png'} />
    </div>
    <div className="text-center">
      <div className="relative inline-block">
        <img src={'../cube-skelleton.svg'} className="w-16" />
        <IconEnvelope className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -mt-1" />
      </div>
      <h2 className="text-4xl lg:text-5xl font-display font-bold text-center inline-flex justify-center items-center w-full mt-4">The newsletter</h2>
      <p className="text-layout-gray-400 mt-2 text-2xl">Once a year, the big info is bundled into your mailbox.</p>
    </div>
    <form className="mt-12">
      <label htmlFor="email" className="font-bold">E-Mail Address</label>
      <div className="">
        <HubspotForm
          portalId='7577247'
          formId='690fc6af-a95e-498e-8dc2-48d5a59fbf2c'
          loading={<div>Loading...</div>}
          submitButtonClass={'rounded-md flex-shrink-0 bg-gradient-to-tr from-secondary-base to-secondary-700 text-white font-bold py-2 px-4 rounded-md-r inline-block'}
        />
      </div>
    </form>
  </>
)

Newsletter.propTypes = {
  
}

Newsletter.defaultProps = {
  
}

export default Newsletter