import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import IconChevronRight from "../../static/icons/chevron-right.svg";

const Button = ({ children, link }) => (
  <Link to={link} className="rounded-md bg-gradient-to-tr from-secondary-base to-secondary-700 font-bold py-2 px-4 inline-flex items-center transform hover:scale-99 transition">
    {children}
    <IconChevronRight className="ml-2 stroke-current w-5 h-5" />
  </Link>
)

Button.propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
}

Button.defaultProps = {
  
}

export default Button
