import * as React from "react"
import PropTypes from "prop-types"
import IconChevronDown from "../../static/icons/chevron-down.svg";
import IconFileText from "../../static/icons/file-text.svg";

const Publication = ({ title, text, type, date, url, className, category }) => (
  <a
    className={`rounded-md bg-layout-gray-900 block w-full p-4 transform hover:scale-99 transition cursor-pointer ${className}`}
    href={url}
    target="_blank"
  >
    {!!category && (
      <div className="inline-block mb-4 p-2 text-xs text-primary-base border-solid border-2 border-primary-base rounded-full">
        {category}
      </div>
    )}
    <div className="flex items-start justify-between">
      <div className="flex">
        <IconFileText className="w-5 min-w-max" />
        <div className="mx-4">
          <p className="text-layout-gray-300 text-base">{date}</p>
          <p className="font-bold">{title}</p>
          {text ? 
            <p className="mt-2">{text}</p>
          : null}
          <p className="font-bold text-primary-base inline-flex items-center mt-2">
            <span>Download</span>
            <IconChevronDown className="ml-1 flex-shrink-0 stroke-current w-6 h-6" />
          </p>
        </div>
      </div>
      <span className="border-2 border-white py-2 px-2 leading-none rounded-md text-base ml-6 font-bold">{type}</span>
    </div>
  </a>
)

Publication.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  date: PropTypes.string,
  url: PropTypes.string,
  category: PropTypes.string
}

Publication.defaultProps = {
  date: "2021/06/24",
  title: 'Publication Title',
  type: 'PDF',
  category: null
}

export default Publication
