import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ siteTitle }) => (
  <>
    <header role="banner" className="py-8 max-w-screen-xl mx-auto px-4 mb-12 relative z-20">
      <div className="md:flex justify-center md:justify-between items-center">
        <Link to="/">
          <img src={'../logo.png'} />
        </Link>
        <div className="bg-black flex justify-center lg:justify-start items-center bg-opacity-60 px-4 py-2 rounded relative z-40">
          <Link
            to="/"
            className="py-2 px-4 rounded-md hover:bg-layout-gray-700 bg-opacity-75 transition"
            activeClassName="bg-layout-gray-700 font-bold"
          >
            Home
          </Link>
          <Link
            to="/technology/"
            className="py-2 px-4 ml-6 rounded-md hover:bg-layout-gray-700 bg-opacity-75 transition"
            activeClassName="bg-layout-gray-700 font-bold"
          >
            Technology
          </Link>
          <Link
            to="/news/"
            className="py-2 px-4 ml-6 rounded-md hover:bg-layout-gray-700 bg-opacity-75 transition"
            activeClassName="bg-layout-gray-700 font-bold"
          >
            News
          </Link>
          <Link
            to="/publications/"
            className="py-2 px-4 ml-6 rounded-md hover:bg-layout-gray-700 bg-opacity-75 transition"
            activeClassName="bg-layout-gray-700 font-bold"
          >
            Publications
          </Link>
          <Link
            to="/events/"
            className="py-2 px-4 ml-6 rounded-md hover:bg-layout-gray-700 bg-opacity-75 transition"
            activeClassName="bg-layout-gray-700 font-bold"
          >
            Events
          </Link>
          <Link
            to="/about/"
            className="py-2 px-4 ml-6 rounded-md hover:bg-layout-gray-700 bg-opacity-75 transition"
            activeClassName="bg-layout-gray-700 font-bold"
          >
            About
          </Link>
        </div>
      </div>
    </header>
    <div className="absolute w-screen top-0 left-0">
      <img src={'../stage-background.jpg'} className="w-full" />
    </div>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
