import * as React from "react"
import PropTypes from "prop-types"

const ConceptTitle = ({ title, number, className}) => (
  <h2 className={`inline-flex items-center font-display font-bold text-3xl ${className}`}>

    <span className="mr-4 w-10 h-10 rounded-full text-2xl border-2 font-bold flex flex-shrink-0 items-center justify-center">
      {number}
    </span>
    {title}
  </h2>
)

ConceptTitle.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
}

ConceptTitle.defaultProps = {
  
}

export default ConceptTitle
