// gatsby-ssr.js and gatsby-browser.js files

import * as React from 'react'
import { PrismicPreviewProvider, componentResolverFromMap } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from './src/utils/LinkResolver'

import HomeTemplate from './src/pages/index'

import './src/styles/global.css';

export const wrapRootElement = ({ element }) => (  
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
          repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
          linkResolver,
          componentResolver: componentResolverFromMap({
            homepage: HomeTemplate,
          })
      },
  ]}
  >
    {element}
  </PrismicPreviewProvider>
)
